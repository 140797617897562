/*
 * 公共的租户参数及请求外层参数
 * */
import { getLocalId } from "@/utils/utils";
import { isPROD } from "@/axios";
import {ElMessage} from "element-plus";
import { ElNotification } from "element-plus";

/**
 * 缓存租户id
 * @param value
 */
function setLocalTenantId(value) {
  localStorage.setItem("tenant_id", value || "");
}

/**
 * 缓存租户id
 * @returns {string|string}
 */
export function getLocalTenantId() {
  return localStorage.getItem("tenant_id") || "";
}

/**
 * 获取 tenantId 后台还没接口获取，先写死
 * @return {string}
 */
export function getTenantId() {
  let _id = getLocalTenantId();
  if (isPROD || _id) {
    return _id;
  } else {
    // api 货代拼(启航)
    // return "1393505912143872";
    // uat 货代拼(启航)
    return "1393321170853888";
    // dev
    return "1392169420103680";
  }
}

/**
 * 获取系统类型 系统类型 1 租户管理、2 TMS、3 OMS
 * @return {string}
 */
export function getSystemType() {
  return "3";
}
/**
 * 登录来源  1  租户平台   2 租户平台免密登录TMS   3  TMS  4  租户平台免密登录OMS  5  OMS  6 小程序
 * @return {string}
 */
export function getSourceType() {
  return getLocalSourceType() || "5";
}
/**
 * 缓存 登录来源
 * @param value
 */
export function setLocalSourceType(value) {
  localStorage.setItem("source_type", value || "");
}

/**
 * 缓存租 登录来源
 * @returns {string|string}
 */
export function getLocalSourceType() {
  return localStorage.getItem("source_type") || "";
}

/**
 * 获取请求公共的外层参数
 * @param params {object} 需要包裹的内层参数
 * @param innerDataKey  {string} 内层参数的key
 * @param appendParams  {Object} 需要追加再包裹层的参数
 * @return {{[p: string]: string|any, requestId: string, tenantId: string, language: UnwrapRef<_ExtractStateFromSetupStore<{language: string|Ref<UnwrapRef<string>>|Ref<any>, setLanguage: function(*=): Promise<void>}>["language"]>}}
 */
export function getCommonRequestParams(
  params,
  innerDataKey = "data",
  appendParams
) {
  const systemStore = useSystemStore();
  return {
    requestId: getLocalId(),
    tenantId: getTenantId(),
    sourceType: getSourceType(),
    language: systemStore.language,
    [innerDataKey]: params,
    ...appendParams,
  };
}

/**
 * 请求租户id
 * @returns {*}
 */
export function requestTenantId() {
  let url = "/oms/tenant";
  let data = {
    url: location.origin,
  };
  return axiosMain
    .post(url, data)
    .then((res) => {
      console.log("请求租户id then()", res);
      let result = res.data || {};
      if(result.successFlag && result.successFlag === true) {
        let id = result.tenantId || "";
        if (id) setLocalTenantId(id);
        return res;
      } else {
        if (isPROD) {
          setLocalTenantId("")
          var errMsg =
            "<div><div><span>域名:</span><span>" +
            result.domain +
            "</span></div><div><span>原因:</span><span>" +
            result.message +
            "</span></div><div><span>具体操作，请联系您的业务员</span></div></div>"
          ElNotification({
            title: "抱歉",
            type: "warning",
            dangerouslyUseHTMLString: true,
            message: errMsg,
            duration: 0
          })
        }
        return res;
      }
    }).catch((res) => {
      console.log("请求租户id catch()", res);
      console.error(res);
      var errMsg = res.message || res.returnMsg;
      if(errMsg){
        return ElMessage.error(errMsg);
      }
    });
}

// 是否需要初始化租户id
let needInitTenantId = true;

/**
 * 自动获取租户id
 * @returns {Promise<void>}
 */
export async function autoGetTenantId() {
  if (needInitTenantId) {
    needInitTenantId = false;
    await requestTenantId();
  }
}
