<template>
  <el-config-provider :locale="elementLocale">
    <!-- <RouterView /> -->
    <router-view v-slot="{ Component }">
      <keep-alive :include="['userGuide', 'newsCenter', 'companyIntroduction']">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <login-popover style="z-index: 9999" />
  </el-config-provider>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useSystemStore } from "@/stores/system";
import {
  getDefaultLange,
  getI18nCurrentLanguage,
  getLocalLanguage,
} from "@/language";
import zh from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
// import {elementLanguageImport, elementLanguageMessages} from "@/language/elment";
import {
  alterWebPrimaryColor,
  getLocalPrimaryColor,
} from "@/utils/utils.system";
import LoginPopover from "@/components/loginPopover/loginPopover.vue";
import { autoRefresh } from "@/utils/autoUpdate";
import { useHomeOtherModule } from "@/views/home/utils/home.utils";
let { getHomeModuleVisibleSetting } = useHomeOtherModule()
const systemStore = useSystemStore();
const elementLocale = ref(zh);
async function setElementLanguage(lang) {
  console.log(`setElementLanguage(${lang})`);
  // 直接引用
  if (lang === "en") {
    elementLocale.value = en;
  } else {
    elementLocale.value = zh;
  }
  // import("./*.js")使用
  // if(lang === 'zh'){
  //   elementLocale.value = zh;
  // }else if(elementLanguageMessages[lang]){
  //   console.log('缓存');
  //   elementLocale.value = elementLanguageMessages[lang];
  // }else if(elementLanguageImport[lang]){
  //   console.log(`import('${lang}')`);
  //   let messages = await elementLanguageImport[lang]();
  //   console.log(messages)
  //   elementLocale.value = messages.default;
  // }
}
let PressCenterFlag = ref(false)
let menuList = ref([])
let menuList1 = [
  { label: "首页", value: "home", to: "/", },
  { label: "物流商城", value: "productList", to: "/productList", },
  { label: "轨迹查询", value: "trajectory", to: "/Trajectory", },
  { label: "用户指南", value: "userGuide", to: "/userGuide", },
  { label: "新闻中心", value: "newsCenter", to: "/news-center", },
  { label: "公司介绍", value: "companyIntroduction", to: "/companyIntroduction", },
]
let menuList2 = [
  { label: "首页", value: "home", to: "/", },
  { label: "物流商城", value: "productList", to: "/productList", },
  { label: "轨迹查询", value: "trajectory", to: "/Trajectory", },
  { label: "用户指南", value: "userGuide", to: "/userGuide", },
  { label: "公司介绍", value: "companyIntroduction", to: "/companyIntroduction", },
]
onMounted(async () => {
  console.log("App.vue onMounted()");
  let defaultLang = getDefaultLange();
  let currentLang = getI18nCurrentLanguage();
  console.log(defaultLang, currentLang);
  if (defaultLang !== currentLang) {
    systemStore.setLanguage(defaultLang);
    setElementLanguage(defaultLang);
  }
  let primaryColor = getLocalPrimaryColor();
  if (primaryColor) alterWebPrimaryColor(primaryColor);

  nextTick(() => {
    if (import.meta.env.MODE !== "development") autoRefresh();
  });
  let res = await getHomeModuleVisibleSetting()
  res.data.forEach(item => {
    if (item.blockCode === "PressCenter") PressCenterFlag.value = item.enableFlag
  })
  menuList.value = PressCenterFlag.value ? menuList1 : menuList2
  localStorage.setItem('menuList', JSON.stringify(menuList.value));
});

watch(
  () => {
    return systemStore.language;
  },
  (val) => {
    console.log("App.vue watch systemStore.language", val);
    setElementLanguage(val);
  }
);
</script>

<style>
.el-overlay {
  /* z-index: 9999 !important; */
}
</style>
