<template>
  <div class="common-login-and-register">
    <g-tabs class="type-tabs" :list="pageTypeList" v-model="pageType" />
    <template v-if="pageType === 1">
      <!-- <g-tabs class="type-tabs" :list="loginTypeList" v-model="loginType" @change="handleChangeLoginType" /> -->
      <template v-if="loginType === 1 || loginType === 2">
        <el-form :model="loginData" ref="loginForm" @submit.prevent.stop>
          <template v-if="loginType === 1">
            <el-form-item
              class="form-item"
              label-width="0px"
              :rules="rulesNotEmpty"
              prop="accountNumber"
            >
              <el-input
                class="form-item-input"
                v-model="loginData.accountNumber"
                placeholder="请输入登录账号"
                clearable
              />
            </el-form-item>
            <el-form-item
              class="form-item"
              label-width="0px"
              :rules="rulesNotEmpty"
              prop="password"
            >
              <el-input
                class="form-item-input"
                v-model="loginData.password"
                placeholder="请输入密码"
                clearable
                type="password"
                show-password
              />
            </el-form-item>
            <el-form-item label="测试账号" v-if="!isPROD">
              <template v-if="testAccountList.length">
                <el-button
                  v-for="item in testAccountList"
                  :key="item.accountNumber"
                  @click="testLogin(item)"
                  type="info"
                  plain
                  class="test-account-btn"
                  >{{ item.accountNumber
                  }}<template v-if="item.described"
                    >({{ item.described }})</template
                  ></el-button
                >
              </template>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item
              class="form-item"
              label-width="0px"
              :rules="rulesNotEmpty"
              prop="phoneNo"
            >
              <el-input
                class="form-item-input"
                v-model="loginData.phoneNo"
                placeholder="请输入手机号"
                clearable
              />
            </el-form-item>
            <el-form-item
              class="form-item"
              label-width="0px"
              :rules="rulesNotEmpty"
              prop="verificationCode"
            >
              <div class="flex width-all">
                <el-input
                  class="form-item-input get-code-input"
                  v-model="loginData.verificationCode"
                  placeholder="请输入验证码"
                  clearable
                  autocomplete="off"
                />
                <el-button
                  type="primary"
                  class="get-code-btn"
                  :disabled="!loginData.phoneNo || loginPhoneCodeCountDown > 0"
                  @click.stop="getLoginPhoneCode(loginData.phoneNo)"
                  >{{
                    loginPhoneCodeCountDown > 0
                      ? loginPhoneCodeCountDown + "S"
                      : "获取验证码"
                  }}</el-button
                >
              </div>
            </el-form-item>
          </template>
        </el-form>
        <template v-for="item in loginTypeList" :key="item.value">
          <div
            v-if="item.value !== loginType"
            class="login-type-check"
            @click="loginType = item.value"
          >
            {{ item.label }}
          </div>
        </template>
        <el-button
          type="primary"
          size="large"
          class="login-btn"
          @click="submitFormLogin(loginForm)"
          :loading="loginLoading"
          >登录</el-button
        >
        <el-checkbox v-model="isAccord" class="accord" v-if="isAccordFlag"
          >已阅读并同意<span @click.stop.prevent="showAccord = true"
            >《用户协议》</span
          ></el-checkbox
        >
      </template>
      <template v-else-if="loginType === 3">
        <div class="over-hide">
          <p style="font-size: 30px; text-align: center">扫码登录开发中...</p>
        </div>
      </template>
      <template v-else>
        <p>异常loginType：{{ loginType }}</p>
      </template>
      <!-- <div class="other-function">
        <div class="flex-1"></div>
        <el-link type="info" class="other-item" @click="switchPageType(2)">免费注册</el-link>
      </div> -->
    </template>
    <template v-else-if="pageType === 2">
      <!-- <g-tabs class="type-tabs" :list="registerTypeList" v-model="registerType" @change="handleChangeRegisterType" /> -->
      <el-form :model="registerData" ref="registerForm" @submit.prevent.stop>
        <el-form-item
          class="form-item"
          label-width="0px"
          :rules="rulesNotEmpty"
          prop="partnerType"
        >
          <el-select
            class="form-item-input"
            v-model="registerData.partnerType"
            placeholder="请选择合作伙伴类型"
            clearable
          >
            <el-option label="公司" :value="1"></el-option>
            <el-option label="个人" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="form-item"
          label-width="0px"
          :rules="rulesNotEmpty"
          prop="accountNumber"
        >
          <el-input
            class="form-item-input"
            v-model="registerData.accountNumber"
            :placeholder="registerData.partnerType == '1' ? '请输入公司名称' : '请输入个人名称'"
            clearable
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          class="form-item"
          label-width="0px"
          :rules="rulesNotEmpty"
          prop="phoneNo"
        >
          <el-input
            class="form-item-input"
            v-model="registerData.phoneNo"
            placeholder="请输入手机号"
            clearable
            autocomplete="off"
          >
            <template #prefix>
              <phone-area-code-select
                v-model="registerData.phoneBefore"
                class="phone-area-code"
                @change="handleSelectPhoneBefore"
              />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          class="form-item"
          label-width="0px"
          :rules="rulesNotEmpty"
          prop="verificationCode"
        >
          <div class="flex width-all">
            <el-input
              class="form-item-input get-code-input"
              v-model="registerData.verificationCode"
              placeholder="请输入验证码"
              clearable
              autocomplete="off"
            />
            <el-button
              type="primary"
              class="get-code-btn"
              :disabled="
                !registerData.phoneNo || registerPhoneCodeCountDown > 0
              "
              @click.stop="getRegisterPhoneCode(registerData.phoneNo)"
              >{{
                registerPhoneCodeCountDown > 0
                  ? registerPhoneCodeCountDown + "S"
                  : "获取验证码"
              }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item
          class="form-item"
          label-width="0px"
          :rules="rulesNotEmpty"
          prop="password"
        >
          <el-input
            class="form-item-input"
            v-model="registerData.password"
            placeholder="请输入密码"
            clearable
            autocomplete="off"
            type="password"
            show-password
            @blur="blurPassword"
          />
        </el-form-item>
        <el-form-item
          class="form-item"
          label-width="0px"
          :rules="formRules.confirmPassword"
          prop="password2"
        >
          <el-input
            class="form-item-input"
            v-model="registerData.password2"
            placeholder="请再次输入密码"
            clearable
            autocomplete="off"
            type="password"
            show-password
          />
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="large"
        class="login-btn"
        @click="submitFormRegister(registerForm)"
        >注册</el-button
      >
      <el-checkbox v-model="isAccord" class="accord" v-if="isAccordFlag"
        >已阅读并同意<span @click.stop.prevent="showAccord = true"
          >《用户协议》</span
        ></el-checkbox
      >
      <!-- <div class="other-function">
        <div class="flex-1"></div>
        <el-link type="info" class="other-item" @click="switchPageType(1)">已有账号，去登录</el-link>
      </div> -->
    </template>
    <template v-else>
      <p>异常pageType：{{ pageType }}</p>
    </template>

    <!-- 协议 -->
    <el-dialog v-model="showAccord">
      <div class="accord-head">
        <h4 class="accord-title">用户协议</h4>
        <div class="close-btn" @click="showAccord = false">
          <el-icon class="icon el-icon-circle-close">
            <Close />
          </el-icon>
        </div>
      </div>
      <div class="accord-body" v-html="accordObj.contentCn"></div>
      <template #footer>
        <div style="text-align: center">
          <el-button
            style="
              width: 30%;
              height: 50px;
              border-radius: 10px;
              font-size: 16px;
            "
            type=""
            size="large"
            @click="showAccord = false"
          >
            取消
          </el-button>
          <el-button
            style="
              width: 30%;
              height: 50px;
              border-radius: 10px;
              color: #ffffff;
              font-size: 16px;
            "
            type="primary"
            size="large"
            @click="
              isAccord = true;
              showAccord = false;
            "
          >
            同意并继续
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import GTabs from "@/components/gTabs/gTabs.vue";
import { getRulesNotEmpty, rulesNotEmpty } from "@/utils/utils.form";
import PhoneAreaCodeSelect from "@/components/phoneAreaCodeSelect/phoneAreaCodeSelect.vue";
import { useGetPhoneCode } from "@/components/loginPopover/getPhoneCode";
import { isPROD } from "@/axios";
import { ElMessage } from "element-plus";
import { $t } from "@/language";
import { getMenuAuthorityList } from "@/stores";
import {
  getParamsBeforeTheJumpLogin,
  removeParamsBeforeTheJumpLogin,
} from "@/utils/utils.router";
import { omsAgreement } from "./commonLoginAndRegister";
import {
  getSourceType,
  getTenantId,
  getLocalTenantId,
} from "@/utils/utils.commonTenantParams";

let isAccord = ref(false);
let showAccord = ref(false);
let isAccordFlag = ref(true);
let accordObj = ref({
  contentCn: "",
  contentEn: "",
});

const emit = defineEmits([
  "update:defaultPageType",
  "update:defaultLoginType",
  "update:defaultRegisterType",
  "loginSuccess",
  "registerSuccess",
]);
const props = defineProps({
  defaultPageType: Number,
  defaultLoginType: Number,
  defaultRegisterType: String,
});
// 测试环境快捷登录账号
const testAccountList = [
  { accountNumber: "omstest01", password: "888888" },
  { accountNumber: "CUS2023100151", password: "123456" },
  { accountNumber: "fexsion", password: "123456" },
  { accountNumber: "CUS202311240301", password: "123456", described: "dev1" },
  { accountNumber: "HX000053", password: "123456", described: "uat1" },
];
// 快捷登录测试账号
function testLogin(item) {
  loginData.accountNumber = item.accountNumber;
  loginData.password = item.password;
  // submitFormLogin(loginForm);
}

// 页面类型 1:登录 2:注册
let pageType = ref(1);
// 页面类型 tabs
let pageTypeList = [
  { label: "登录", value: 1 },
  { label: "注册", value: 2 },
];

// 登录类型,1 账号密码登录；2 手机验证码登录；3 扫描登录（暂时没有）
let loginType = ref(1);
// 登录类型 tabs
let loginTypeList = [
  { label: "账号登录", value: 1 },
  { label: "短信登录", value: 2 },
  // {label: "微信登录", value: 3, disabled:true,},
];
// 注册类型
let registerType = ref("G");
// 注册类型 tabs
let registerTypeList = [
  { label: "注册", value: "G" },
  // {label: "企业注册", value: "Q", disabled:true,},
  // {label: "海外注册", value: "H", disabled:true,},
];

// let actionType = ref('login')
// let actionList = [
//   { label: '登录', value: 'login' },
//   { label: '注册', value: 'register' }
// ]

watch(
  () => props.defaultPageType,
  (newValue) => (pageType.value = newValue)
);
watch(
  () => props.defaultLoginType,
  (newValue) => (loginType.value = newValue)
);
watch(
  () => props.defaultRegisterType,
  (newValue) => (registerType.value = newValue)
);

onMounted(() => {
  if (props.defaultPageType) pageType.value = props.defaultPageType;
  if (props.defaultLoginType) loginType.value = props.defaultLoginType;
  if (props.defaultRegisterType) registerType.value = props.defaultRegisterType;
  getOmsAgreement();
});

const userStore = useUserStore();
const router = useRouter();
// 登录表单
const loginForm = ref(null);
const loginLoading = ref(false);
// 登录表单数据
let loginData = reactive({
  // 用户名
  accountNumber: "",
  // 密码
  password: "",
  // 验证码
  verificationCode: "",
  // 登录类型,1 账号密码登录；2 手机验证码登录；3 扫描登录（暂时没有）
  loginType: "",
  // 登录手机号
  phoneNo: "",
});
// 注册表单
const registerForm = ref(null);
const registerLoading = ref(false);
// 注册表单数据
let registerData = reactive({
  partnerType: 1,
  // 用户名
  accountNumber: "",
  // 公司名称
  companyName: "",
  // 密码
  password: "",
  password2: "",
  // 手机号码
  phoneNo: "",
  // 手机前区号
  phoneBefore: "86",
  // 邮箱
  email: "",
  // 验证码
  verificationCode: "",
  // 用户注册类型
  userType: "",
});

// 表单校验规则
const formRules = {
  // 再次确认密码
  confirmPassword: [
    rulesNotEmpty,
    { validator: validatePass, trigger: "blur" },
  ],
};
/**
 * 校验规则：确认密码
 * @param rule
 * @param value
 * @param callback
 */
function validatePass(rule, value, callback) {
  if (value !== registerData.password) {
    callback(new Error("两次输入密码不一致!"));
  } else {
    callback();
  }
}

/**
 * 清空当前表单校验信息
 * @param isReset {boolean} true:重置该表单项，并移除校验结果；false: 并移除校验结果；
 * @return {boolean}
 */
function clearValidate(isReset = false) {
  let formEl;
  if (unref(pageType) === 1) {
    formEl = loginForm;
  } else {
    formEl = registerForm;
  }
  formEl = unref(formEl);
  if (!formEl) return false;
  if (isReset && formEl.resetFields) {
    formEl.resetFields();
  } else if (formEl.clearValidate) {
    formEl.clearValidate();
  }
}

// 切换手机区号
function handleSelectPhoneBefore(value) {}
// 注册时修改密码1
function blurPassword() {
  let formEl = unref(registerForm);
  if (registerData.password2 && formEl && formEl.validateField) {
    formEl.validateField(["password2"], (valid, fields) => {
      // console.log(valid,fields)
    });
  }
}
// 注册短信验证码相关
let {
  phoneCodeCountDown: registerPhoneCodeCountDown,
  clickPhoneCode: getRegisterPhoneCode,
} = useGetPhoneCode("register", "注册");
// 登录短信验证码相关
let {
  phoneCodeCountDown: loginPhoneCodeCountDown,
  clickPhoneCode: getLoginPhoneCode,
} = useGetPhoneCode("login");

/**
 * 切换 页面类型
 * @param type
 */
function switchPageType(type = 1) {
  pageType.value = type;
  emit("update:defaultPageType", type);
}

function handleChangeLoginType(value) {
  loginType.value = value;
  emit("update:defaultLoginType", value);
}
function handleChangeRegisterType(value) {
  registerType.value = value;
  emit("update:defaultRegisterType", value);
}

/**
 * 点击登录
 */
async function submitFormLogin(formEl) {
  if (isPROD) {
    const tenantId = getLocalTenantId();
    if (!tenantId) ElMessage.error("当前租户已过期，请联系您的业务员");
  }
  formEl = unref(formEl);
  if (!formEl) return;
  let result = await formEl.validate((valid, fields) => {
    console.log(valid, fields);
    if (valid) {
      console.log("submit!");
      return true;
    } else {
      console.log("error submit!", fields);
      return false;
    }
  });
  if (!result) return;
  if (!isAccord.value) return ElMessage.warning("请先阅读并同意协议");
  loginLoading.value = true;
  const loginResult = await sendLogin();
  setTimeout(() => {
    loginLoading.value = false;
  }, 500);
  if (loginResult.returnCode !== "200")
    return ElMessage.error(loginResult.message || $t("login.systemException"));
  userStore.setToken(loginResult.data.token);
  userStore.setUserInfo(loginResult.data);
  ElMessage.success(loginResult.message || $t("login.loginSuccess"));
  clearValidate(true);
  // 登录时清空页签
  sessionStorage.setItem("tabs", "[]");
  await getMenuAuthorityList();
  emit("loginSuccess");
  loginSuccess();
}

/**
 * 发送登录请求
 * @return {Promise<AxiosResponse<*> | {code: number, message: *}>}
 */
function sendLogin() {
  let data = unref(loginData);
  let type = unref(loginType);
  let params;
  if (type === 1) {
    params = {
      // 用户名
      name: data.accountNumber,
      // 密码
      pwd: data.password,
      // 登录类型,1 账号密码登录；2 手机验证码登录；3 扫描登录（暂时没有）
      loginType: type,
      sourceType: getSourceType(),
    };
  } else if (type === 2) {
    params = {
      // 用户名
      name: data.phoneNo,
      // 验证码
      verificationCode: data.verificationCode,
      // 登录类型,1 账号密码登录；2 手机验证码登录；3 扫描登录（暂时没有）
      loginType: type,
      sourceType: getSourceType(),
    };
  } else {
    return Promise.reject(new Error("暂时只支持账号密码/手机验证码登录"));
  }
  return api.user.login(params).catch((res) => {
    return {
      code: -1,
      message: res.message,
    };
  });
}

/**
 * 登录成功后（获取登录前记录的操作步骤）
 */
function loginSuccess() {
  let user_info = JSON.parse(localStorage.getItem("user_info")) || {} || "";
  let use_btn_permission_list =
    JSON.parse(localStorage.getItem("use_btn_permission_list")) || [];
  if (
    Array.isArray(use_btn_permission_list) &&
    use_btn_permission_list.length > 0
  ) {
    api.operate.addSysUserOperateLog({
      customerId: user_info.customerId,
      userId: user_info.id,
      operationButtonCode: "USER_LOGIN",
      operationContent: "",
      appType: "oms",
      productPriceHeaderId: "",
      loginType: "",
      remark: "",
    });
  }

  let recordBeforeLogin = getParamsBeforeTheJumpLogin();
  if (recordBeforeLogin) {
    router.push(recordBeforeLogin);
    removeParamsBeforeTheJumpLogin();
  } else {
    // router.push({
    //   path:"/"
    // })
    location.reload();
  }
}
/**
 * 点击登录
 */
async function submitFormRegister(formEl) {
  formEl = unref(formEl);
  if (!formEl) return;
  let result = await formEl.validate((valid, fields) => {
    console.log(valid, fields);
    if (valid) {
      console.log("submit!");
      return true;
    } else {
      console.log("error submit!", fields);
      return false;
    }
  });
  if (!result) return;
  if (!isAccord.value) return ElMessage.warning("请先阅读并同意协议");
  registerLoading.value = true;
  const loginResult = await sendRegister();
  setTimeout(() => {
    registerLoading.value = false;
  }, 500);
  if (loginResult.returnCode !== "200")
    return ElMessage.error(loginResult.message || $t("login.systemException"));
  api.operate.addSysUserOperateLog({
    customerId: loginResult.data.customerId,
    userId: loginResult.data.userId,
    operationButtonCode: "USER_REGISTER",
    operationContent: "",
    appType: "oms",
    productPriceHeaderId: "",
    loginType: "",
    remark: "",
  });
  ElMessage.success("已注册成功，稍后工作人员联系您完成信息审核。");
  emit("registerSuccess");
  clearValidate(true);
  registerSuccess();
}
/**
 * 发送注册请求
 * @return {Promise<AxiosResponse<*> | {code: number, message: *}>}
 */
function sendRegister() {
  let data = unref(registerData);
  let type = unref(registerType);
  console.log("注册类型:" + type);
  if (type !== "G" && type !== 1)
    return Promise.reject(new Error("暂时只支持个人注册"));
  let params = {
    partnerType: data.partnerType,
    // 用户名
    name: data.accountNumber,
    // 密码
    pwd: data.password,
    // 验证码
    verificationCode: data.verificationCode,
    // 登录类型,1 账号密码登录；2 手机验证码登录；3 扫描登录（暂时没有）
    userType: type,
    // 公司名称
    companyName: data.companyName,
    // 手机号码
    phoneNo: data.phoneNo,
    // 	邮箱
    email: data.email,
  };
  return api.user.register(params).catch((res) => {
    return {
      code: -1,
      message: res.message,
    };
  });
}

/**
 * 注册成功
 */
function registerSuccess() {
  switchPageType(1);
  handleChangeLoginType(1);
}

/**
 * 获取协议
 */
function getOmsAgreement() {
  let tenantId = getTenantId();
  console.log(tenantId, "-----tenantId");
  omsAgreement({ tenantId, agreementType: 1 }).then(
    (res) => {
      console.log(res, "-----用户协议");
      if (res.returnCode != 200) return;
      if (res.data && res.data.contentCn) {
        // 有内容
        accordObj.value = res.data;
        isAccordFlag.value = true;
      } else {
        isAccordFlag.value = false;
        isAccord.value = true;
      }
    },
    (err) => {
      console.log(err, "----获取协议失败");
      isAccordFlag.value = false;
      isAccord.value = true;
    }
  );
}

defineExpose({
  clearValidate,
});
</script>

<style scoped lang="scss">
.type-tabs {
  height: 50px;
  // margin-bottom: 35px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eeeeee;

  :deep(.g-tabs--item) {
    padding: 0 20px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    &.is-active {
      color: #141414;
    }

    &:not(.is-active) {
      color: #999999;
    }

    &:before {
      left: 0;
      right: 0;
      height: 2px;
    }
  }
}

.login-btn {
  display: block;
  width: 100%;
  height: 50px;
  // font-size: 18px;
  font-size: 16px;
  // margin-top: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.other-function {
  display: flex;

  .other-item {
    color: #333333;
    margin-left: 10px;
  }
}

.form-item {
  margin-bottom: 22px;

  :deep(.el-form-item__label) {
    display: none;
  }

  :deep(.el-form-item__content) {
  }
}

.form-item-input {
  height: 50px;
  width: 100%;
  // border-radius: 10px;
  border-radius: 4px;
  // font-size: 18px;
  font-size: 14px;

  :deep(.el-input__wrapper) {
    border-radius: inherit;
  }
  &.el-select:deep .el-input .el-input__wrapper {
    height: 50px!important;
    border-radius: 4px;
  }
}

.phone-area-code {
  width: 90px;

  :deep(.select-trigger) {
    .el-input {
      .el-input__wrapper {
        box-shadow: none !important;
      }
    }
  }
}

.get-code-input {
  flex: 1;

  :deep(.el-input__wrapper) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.get-code-btn {
  margin-left: 10px;
  width: 128px;
  height: 50px;
  // border-radius: 0 10px 10px 0;
  // font-size: 18px;
  font-size: 16px;
  font-weight: 400;
}

.login-type-check {
  margin-bottom: -10px;
  color: var(--el-color-primary);
  cursor: pointer;
}
.accord span {
  color: var(--el-color-primary);
}

.accord-head {
  // padding: 20px 0;
  display: flex;
  justify-content: center;
  position: relative;

  .close-btn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    width: 54px;
    height: 54px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--el-color-info);
    font-size: 14px;

    &:hover {
      color: var(--el-color-primary);
    }
  }
}

.accord-body {
  padding: 20px;
  height: 600px;
  overflow-y: auto;
}
</style>