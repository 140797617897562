<template>
  <div class="fixed-tools">
    <div class="fixed-tools-list">
      <!--      <div class="fixed-tools-item">-->
      <!--        <div class="icon-box">-->
      <!--          <img :src="ossPath + '/components/fixedTools/tools.png'" alt="" class=""/>-->
      <!--        </div>-->
      <!--        <div class="label">小工具</div>-->
      <!--      </div>-->
      <el-popover placement="left" :width="200" trigger="hover">
        <template #reference>
          <div class="fixed-tools-item">
            <div class="icon-box">
              <i class="iconfont m-icon-kefu"></i>
            </div>
            <div class="label">联系我们</div>
          </div>
        </template>
        <div class="more-box">
          <div class="more-box--img-box">
            <img :src="systemStore.webFooterInfo.serviceQrCode" alt="" class="">
          </div>
          <div class="more-box--label">联系客服</div>
        </div>
      </el-popover>
      <template v-if="systemStore.systemConfig.openWechatFlag">
        <div class="line"></div>
        <el-popover placement="left" :width="200" trigger="hover">
          <template #reference>
            <div class="fixed-tools-item">
              <div class="icon-box">
                <i class="iconfont m-icon-gongzhonghao"></i>
              </div>
              <div class="label">公众号</div>
            </div>
          </template>
          <div class="more-box">
            <div class="more-box--img-box">
              <!--            <img :src="imgWx" alt="" class="">-->
              <img :src="systemStore.webFooterInfo.weChatQrCode" alt="" class="">
            </div>
            <div class="more-box--label">微信公众号二维码</div>
          </div>
        </el-popover>
      </template>
      <template v-if="systemStore.systemConfig.miniProgramUrlFlag">
        <div class="line"></div>
        <el-popover placement="left" :width="200" trigger="hover">
          <template #reference>
            <div class="fixed-tools-item">
              <div class="icon-box">
                <i class="iconfont m-icon-xiaochengxu"></i>
              </div>
              <div class="label">小程序</div>
            </div>
          </template>
          <div class="more-box">
            <div class="more-box--img-box">
              <!--            <img :src="imgApplet" alt="" class="">-->
              <img :src="systemStore.webFooterInfo.weChatAppletQrCode" alt="" class="">
            </div>
            <div class="more-box--label">微信小程序码</div>
          </div>
        </el-popover>
      </template>
    </div>
    <div class="fixed-tools-item is-top" v-if="visibleTop" @click="clickTop">
      <div class="icon-box">
        <i class="iconfont m-icon-zhiding"></i>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ossPath} from "../../../config/variables";
import {scrollTo} from "@/components/fixedTools/fixedTools.utils";
import {useSystemStore} from "@/stores";
const props = defineProps({
  visibleTop:Boolean,
  topCallback:Function,
})
// 联系我们 二维码图片链接
let imgContactUs = "";
// 微信公众号 二维码图片链接
let imgWx = "";
// 小程序 二维码图片链接
let imgApplet = "";
function clickTop(){
  if(typeof props.topCallback === "function" && props.topCallback){
    props.topCallback();
  }else{
    scrollTo(0)
  }
}
let systemStore =  useSystemStore();

onMounted(()=>{
  systemStore.autoGetWebFooterInfo();
})
</script>

<style scoped lang="scss">
.fixed-tools{
  position: fixed;
  z-index: 9;
  width: 70px;
  right: 15px;
  top: 50%;
  margin-top: -205px;

}
$--item-radius:10px;
@mixin linearGradient(){
  //background: linear-gradient(101deg, red, black);
  background: linear-gradient(90deg, var(--el-color-primary-light-5), var(--el-color-primary));
  background: linear-gradient(90deg, var(--el-color-primary-light-5), var(--el-color-primary));
  border-radius: $--item-radius;
}
.fixed-tools-list{
  // @include linearGradient;
  width: 70px;
  background: #FFFFFF;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: $--item-radius;
}
.fixed-tools-item{
  position: relative;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & + & {
    &:before{
      content: "";
      display: block;
      width: 50%;
      height: 1px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 25%;
      background-color: white;
    }
  }
  &:first-child{
    border-radius: $--item-radius $--item-radius 0 0;
  }
  &:last-child{
    border-radius: 0 0 $--item-radius $--item-radius;
  }
  .icon-box{
    $--icon-size:32px;
    width: $--icon-size;
    height: $--icon-size;
    i {
      font-size: $--icon-size;
      color: var(--el-color-primary);
    }
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .label{
    font-size: 12px;
    color: #111111;
    line-height: 20px;
    margin-top: 5px;
  }
  &.is-top{
    height: 70px;
    margin-top: 14px;
    background: #FFFFFF;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: $--item-radius;
    // @include linearGradient;
  }
  &:hover {
    .icon-box i {
      opacity: .7;
    }
    .label {
      color: var(--el-color-primary);
    }
  }
}
.more-box{
  &--img-box{
    display: block;
    width: 160px;
    height: 160px;
    margin: 0 auto;
    img{
      display: block;
      border: none;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &--label{
    text-align: center;
    margin-top: 12px;
  }
}
.line {
  width: 40px;
  height: 1px;
  border-top: 1px solid #EEEEEE;
  margin: 0 auto;
}
</style>