<template>
  <ul class="menu-list">
    <li class="menu-item" :class="{'is-active':currentMenuValue === item.value}" v-for="(item,index) in menuData" :key="item.value">
      <div class="menu-item--a"  v-if="currentMenuValue === item.value">{{ item.label }}</div>
      <router-link class="menu-item--a" :to="item.to" v-else>{{ item.label }}</router-link>
    </li>
  </ul>
</template>

<script setup>
import { useSystemStore } from "@/stores/system";

const systemStore = useSystemStore();
const props = defineProps({
  // 菜单列表
  menuList:{
    type:Array,
    default:()=>[]
  },
  // 当前菜单激活项
  currentMenuValue:{
    type:String,
    default:"home",
  },
})
let menuList = JSON.parse(localStorage.getItem('menuList'))
const menuData = computed(() => {
  return menuList.map( v=> {
    if (v.value === 'userGuide') v.label = systemStore.webFooterInfo?.pageConfigList.find(el => el.pageCode === 'User_Guide')?.pageName || v.label
    if (v.value === 'companyIntroduction') v.label = systemStore.webFooterInfo?.pageConfigList.find(el => el.pageCode === 'Company_Introduction')?.pageName || v.label
    if (v.value === 'newsCenter') v.label = systemStore.webFooterInfo?.pageConfigList.find(el => el.pageCode === 'News_Center')?.pageName || v.label
    return v
  })
})
</script>

<style scoped>

</style>