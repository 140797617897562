<template>
    <div class="price-blurring" :class="{
        'is-blurring': isBlurring,
        'inline': inline,
        'inline-block': inlineBlock,
    }" :style="[customStyle, isBlurring ? customBlurringStyle : '',]">
        <slot :isBlurring="isBlurring">
            <div class="hoverBox" :class="{ ell: true }">
                <template v-if="inquiry">
                    <span class="price-blurring--price" v-if="isBlurring">***</span>
                    <span class="price-blurring--price" v-else>单询</span>
                </template>
                <template v-else>
                    <span class="price-blurring--currency" v-if="currency">{{ currency }}</span>
                    <span class="price-blurring--price" v-if="isBlurring">***</span>
                    <template v-else>
                        <el-tooltip placement="bottom-start" trigger="hover"
                            :disabled="(('' + props.price).length + unit.length) < 7" class="box-item" effect="dark"
                            :content="currency + ' ' + price + ' / ' + unit">
                            <span class="price-blurring--price" :style="[customPriceStyle]" v-if="price">{{ price
                                }}</span>
                        </el-tooltip>
                        <span class="price-blurring--origin-price" :style="[customOriginPriceStyle]" v-if="originPrice">{{
                            originPrice
                        }}</span>
                    </template>
                    <span class="price-blurring--unit" v-if="unit && !isBlurring">{{ unit }}</span>
                </template>
            </div>
        </slot>
    </div>
</template>

<script setup>
import { useSystemStore } from "@/stores";
import { userPriceBlurring } from "@/components/priceBlurring/priceBlurring";
import { busShowLoginPopover } from "@/utils/bus";
import { onMounted } from "vue";
const props = defineProps({
    //价格显示类型，没有值时按默认逻辑处理，1：强制不显示；  2：强制显示；
    // priceDisplayType: Number,
    // 币种
    currency: {
        type: String,
        default: "",
    },
    // 价格
    price: {
        type: [String, Number],
        default: "-",
    },
    // 原价
    originPrice: {
        type: [String, Number],
        default: "",
    },
    // 计费单位
    unit: {
        type: String,
        default: "",
    },
    inline: Boolean,
    inlineBlock: Boolean,
    customStyle: Object,
    customBlurringStyle: Object,
    customPriceStyle: Object,
    customOriginPriceStyle: Object,
    inquiry: Boolean,
})
const systemStore = useSystemStore();
const userStore = useUserStore();
// 是否需要模糊价格
let { isBlurring } = userPriceBlurring();

/**
 * 替换价格文本
 * @param text
 * @returns {string}
 */
function replaceText(text = "") {
    return `${text}`.replace(/\d+/g, "*");
}
let activeClass = ref(false);
onMounted(() => {
    // console.log(('' + props.price).length, '111122');
    // if (('' + props.price).length + props.unit.length > 6) {
    //     activeClass.value = true;
    // } else {
    //     activeClass.value = false;
    // }
})
</script>

<style scoped lang="scss">
.price-blurring {
    --pb-color: #666;
    //--pb-font-size: 14px;
    --pb-price-color: #F61010;
    --pb-price-font-size: 1.28em;
    color: var(--pb-color, inherit);
    font-size: var(--pb-font-size, inherit);

    &--currency {
        color: var(--pb-color);
    }

    &--price {
        color: var(--pb-price-color, inherit);
        font-size: var(--pb-price-font-size, inherit);
    }

    &--origin-price {}

    &--unit {
        color: var(--pb-color);
    }
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.is-blurring {}

.hoverBox {
    position: relative;

    &:hover {
        .details-check {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            position: absolute;
            top: -160px;
            left: 50%;
            transform: translateX(-50%);
            width: 154px;
            height: 177px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            z-index: 9999;
            cursor: default;

            img {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }

            .title {
                font-size: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            .btn {
                width: 105px;
                height: 30px;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #2A63ED;
                text-align: center;
                line-height: 30px;
                color: #2A63ED;
                cursor: pointer;
            }
        }
    }
}

.details-check {
    display: none;
}

.price-blurring--price {
    // font-size: 17px;
    margin: 0 4px;
}

.ell {
    white-space: nowrap; //不换行
    overflow: hidden; //超出部分隐藏
    text-overflow: ellipsis; //文本溢出显示省略号
}
</style>