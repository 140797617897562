<template>
  <div class="price-blurring" :class="{
    'is-blurring': isBlurring,
    'inline': inline,
    'inline-block': inlineBlock,
  }" :style="[customStyle, isBlurring ? customBlurringStyle : '',]">
    <slot :isBlurring="isBlurring">
      <div class="hoverBox">
        <div class="details-check" @click.stop="" v-if="isBlurring">
          <template v-if="bluringType === 'login'">
            <img src="../../assets/images/order_lock.png" alt="">
            <div class="title">登录即可查看运价</div>
            <el-button type="primary" plain @click="busShowLoginPopover()">去登录</el-button>
          </template>
          <template v-else>
            <img :src="ossPath + '/common/unapproval-ico.png'" alt="">
            <div class="title">审核通过即可查看运价</div>
          </template>
        </div>
        <span class="price-blurring--currency" v-if="currency">{{ currency }}&nbsp;</span>
        <span class="price-blurring--price" v-if="isBlurring">***</span>
        <template v-else>
          <span class="price-blurring--price" :style="[customPriceStyle]" v-if="price">{{ price }}</span>
          <span class="price-blurring--origin-price" :style="[customOriginPriceStyle]" v-if="originPrice">{{ originPrice
          }}</span>
        </template>
        <span class="price-blurring--unit" v-if="unit && !isBlurring">/{{ unit }}</span>
      </div>
    </slot>
  </div>
</template>

<script setup>
import { useSystemStore } from "@/stores";
import { userPriceBlurring } from "@/components/priceBlurring/priceBlurring";
import { busShowLoginPopover } from "@/utils/bus";
import {ossPath} from "@/../config/variables";

const props = defineProps({
  //价格显示类型，没有值时按默认逻辑处理，1：强制不显示；  2：强制显示；
  // priceDisplayType: Number,
  // 币种
  currency: {
    type: String,
    default: "",
  },
  // 价格
  price: {
    type: [String, Number],
    default: "-",
  },
  // 原价
  originPrice: {
    type: [String, Number],
    default: "",
  },
  // 计费单位
  unit: {
    type: String,
    default: "",
  },
  inline: Boolean,
  inlineBlock: Boolean,
  customStyle: Object,
  customBlurringStyle: Object,
  customPriceStyle: Object,
  customOriginPriceStyle: Object,
})
const systemStore = useSystemStore();
const userStore = useUserStore();
// 是否需要模糊价格
let { isBlurring, bluringType } = userPriceBlurring();

/**
 * 替换价格文本
 * @param text
 * @returns {string}
 */
function replaceText(text = "") {
  return `${text}`.replace(/\d+/g, "*");
}
</script>

<style scoped lang="scss">
.price-blurring {
  //--pb-color: #666;
  //--pb-font-size: 14px;
  --pb-price-color: #FF2A14;
  --pb-price-font-size: 1.28em;
  color: var(--pb-color, inherit);
  font-size: var(--pb-font-size, inherit);

  &--currency {}

  &--price {
    color: var(--pb-price-color, inherit);
    font-size: var(--pb-price-font-size, inherit);
  }

  &--origin-price {}

  &--unit {}
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.is-blurring {}

.hoverBox {
  position: relative;

  &:hover {
    .details-check {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      position: absolute;
      // top: -160px;
      bottom: 98%;
      left: 50%;
      transform: translateX(-50%);
      // width: 154px;
      // height: 177px;
      width: 140px;
      min-height: 130px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 9999;
      cursor: default;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      .title {
        // max-width: 84px;
        white-space: break-spaces;
        text-align: center;
        line-height: 1.5;
        word-break: break-all;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .btn {
        width: 105px;
        height: 30px;
        background: #FFFFFF;
        border-radius: 4px;
        // border: 1px solid #2A63ED;
        border: 1px solid var(--el-color-primary);
        text-align: center;
        line-height: 30px;
        // color: #2A63ED;
        color: var(--el-color-primary);
        cursor: pointer;
      }
    }
  }
}

.details-check {
  display: none;
}
</style>
