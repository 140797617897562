import { getHomeInfoList } from "@/api/modules/home";
import { commonNewsType } from "@/utils/utils.type";
import { getTenantId } from "@/utils/utils.commonTenantParams";
import { htmlStringToTextString } from "@/utils/utils";

// 获取首页其他模块内容（我的优势、合作伙伴、新闻中心等）
export function useHomeOtherModule() {
  // 首页各模块是否展示配置项
  let homeModuleVisibleSetting = [];
  // 首页配置为需要展示的模块名称集
  let homeVisibleModuleNames = ref([]);
  const homeModuleOb = ref({})
  /**
   * 获取首页各模块是否展示配置项
   * @returns {*}
   */
  function getHomeModuleVisibleSetting() {
    let _tenantId = getTenantId();
    let url = `/oms/officalWebsiteInfo/getBlockIsShowList/${_tenantId}`;
    let data = {}
    return axiosMain.get(url)
      .then(res => {
        let result = res.data || [];
        // result.forEach(item=>{
        //   if(item.blockCode === "PressCenter") PressCenterFlag = item.enableFlag;
        // })
        // console.log(PressCenterFlag, 'Mimin123');
        let names = [];
        let homeModuleResult = {}
        result.forEach(item => {
          homeModuleResult[item.blockCode] = item
          if (item.enableFlag) names.push(item.blockCode);
        })
        homeModuleVisibleSetting = result;
        homeVisibleModuleNames.value = names;

        homeModuleOb.value = homeModuleResult
        return res;
      })
      .catch(res => {
        console.error(res);
        homeModuleVisibleSetting = [];
        homeVisibleModuleNames.value = [];
        homeModuleOb.value = {}
      })
  }

  // 我的优势列表
  let myAdvantageList = ref([]);

  /**
   * 获取首页我的优势数据
   * @returns {Promise<AxiosResponse<*>>}
   */
  function getMyAdvantageList() {
    return api.home.getHomeInfoList("MyAdvantage")
      .then(res => {
        let result = res.data || {};
        let list = result.cmsContentList || [];
        list.forEach(item => {
          item.label = item.nameCn || "";
          if (item.content) {
            let textStr = "";
            textStr = htmlStringToTextString(item.content, undefined, 100);
            item.description = textStr;
          } else {
            item.description = "";
          }
        })
        myAdvantageList.value = list;
        return res;
      })
      .catch(res => {
        console.error(res);
        myAdvantageList.value = [];
      })
  }

  // 合作伙伴列表
  let cooperativePartnerList = ref([]);

  /**
   * 获取首页我的合作伙伴列表
   * @returns {Promise<AxiosResponse<*>>}
   */
  function getCooperativePartnerList() {
    return api.home.getHomeInfoList("CooperativeClient")
      .then(res => {
        let result = res.data || {};
        let list = result.cmsContentList || [];
        list.forEach((item, index) => {

          if (index === 0) item.cover = item.thumbnailUrl || "";
          item.name = item.nameCn || "";
        })
        console.log("我的合作伙伴", unref(list));
        cooperativePartnerList.value = list;
        // randomCooperativePartnerList();
        return res;
      })
      .catch(res => {
        console.error(res);
        cooperativePartnerList.value = [];
      })
  }
  // 随机生成合作伙伴列表
  function randomCooperativePartnerList() {
    let roundLength = Math.round(Math.random() * 100);
    cooperativePartnerList.value = Array(roundLength).fill(1).map((item, index) => index + 1);
  }


  // 新闻数据缓存
  let newsDataCache = {};
  // 新闻分类tabs列表
  let newsTabs = ref([]);
  // 新闻分类tabs 当前激活的值
  let newsTabsCurrent = ref("");
  // 当前展示的新闻列表
  let newsList = ref([]);
  // 新闻列表 是否正在加载
  let newsLoading = ref(false);
  let newsFileUrl = ref('')


  /**
   * 获取首页新闻中心数据
   * @returns {Promise<AxiosResponse<any>>}
   */
  function getHomeNews() {
    newsLoading.value = true;
    return getHomeInfoList("PressCenter")
      .then(res => {
        newsLoading.value = false;
        let result = res.data || {};
        let list = result.cmsContentList || [];
        let { tabs, dataMap, firstTab } = disposeHomeNewsData(list);
        newsDataCache = dataMap;
        newsTabs.value = tabs;
        if (firstTab) {
          changeNewsTab(firstTab.value);
        }
        newsFileUrl.value = result.jumpUrl || '';
        return res;
      })
      .catch(res => {
        console.error(res);
        newsLoading.value = false;
        newsTabs.value = [];
        newsTabsCurrent.value = "";
        newsList.value = [];
      })
  }


let CustomDefinitionHomeList = ref([]);
  /**
   * 获取首页自定义数据
   * @returns {Promise<AxiosResponse<any>>}
   */
  function getHomeCustomerDefination() {
    newsLoading.value = true;
    return getHomeInfoList("CustomDefinitionHome")
      .then(res => {
        newsLoading.value = false;
        let result = res.data || {};
        CustomDefinitionHomeList.value = result.contentList;
        console.log(result, 'result1111111111111111');
        // let list = result.cmsContentList || [];
        // let { tabs, dataMap, firstTab } = disposeHomeNewsData(list);
        // newsDataCache = dataMap;
        // newsTabs.value = tabs;
        // if (firstTab) {
        //   changeNewsTab(firstTab.value);
        // }
        return result.contentList;
      })
      .catch(res => {
        console.error(res);
        newsLoading.value = false;
        // newsTabs.value = [];
        // newsTabsCurrent.value = "";
        // newsList.value = [];
      })
  }

  /**
   * 处理获取的首页新闻数据
   * @param list
   * @returns {{dataMap: {},currentTabValue:Number|string, tabs: {label: string, value: number}[]}}
   */
  /**
   *
   * @param list
   * @returns {{dataMap: {}, tabs: {label: string, value: number}[], firstTab: Object}}
   */
  function disposeHomeNewsData(list = []) {
    let tabs = commonNewsType.map(item => Object.assign({}, item));
    let dataMap = {};
    list.forEach(item => {
      if (item.releaseTime) {
        item.releaseTimeText = dayjs(item.releaseTime).format("YYYY-MM-DD HH:mm:ss");
      } else {
        item.releaseTimeText = "";
      }
      if (item.content) {
        let textStr = "";
        textStr = htmlStringToTextString(item.content, undefined, 100);
        item.description = textStr;
      } else {
        item.description = "";
      }
      let target = tabs.find(tabItem => tabItem.value === item.cmsType);
      if (target) {
        let type = target.value;
        if (!isArray(dataMap[type])) dataMap[type] = [];
        dataMap[type].push(item);
      }
    })
    Object.keys(dataMap).forEach(key => {
      let son = dataMap[key] || []
      if (son.length > 3) son.length = 3;
    })
    tabs = tabs.filter(item => !!dataMap[item.value]);
    let firstTab = undefined;
    if (tabs.length) {
      firstTab = tabs[0];
    }
    return { tabs, dataMap, firstTab };
  }

  /**
   * 切换首页新闻tabs
   * @param value
   */
  function changeNewsTab(value) {
    if (newsTabsCurrent.value !== value) {
      newsTabsCurrent.value = value;
    }
    let list = newsDataCache[value] || [];
    newsList.value = list;
  }

  return {
    homeVisibleModuleNames,
    getHomeModuleVisibleSetting,

    myAdvantageList,
    getMyAdvantageList,

    cooperativePartnerList,
    getCooperativePartnerList,

    newsDataCache,
    newsTabs,
    newsTabsCurrent,
    newsList,
    newsLoading,
    getHomeNews,
    changeNewsTab,
    homeModuleOb,
    getHomeCustomerDefination,
    CustomDefinitionHomeList,
    newsFileUrl
  }
}
