<template>
  <div @mousemove="watchMouseStuff">
    <el-table class="simple-data-table" tooltip-effect="dark" ref="customTable" :data="tableData"
      :height="height ? height : undefined" :max-height="maxHeight ? maxHeight : undefined" :size="size"
      :border="border" :header-cell-style="headerCellStyle">
      <template v-for="(item, index) in theadData" :key="`${item.props || index}`">
        <el-table-column v-if="!item.flag" :align="item.align ? item.align : ''"
          :header-align="item.headerAlign ? item.headerAlign : ''" :label="item.label ? item.label : null"
          :fixed="item.fixed !== undefined ? item.fixed : null" :width="item.width ? item.width : undefined"
          :min-width="item.minWidth ? item.minWidth : undefined">
          <template #default="scope">
            <slot :row="scope.row" :index="scope.$index" :theadItem="item">
              <template v-if="item.isPrice">
                <el-tooltip v-if="isBlurring" transition="linear" show-after="100" effect="light" placement="top" popper-class="details-pop">
                  <template #content>
                    <div
                      style=' width: 140px;height: 130px;gap:14px; display:flex;flex-direction:column;align-items:center;justify-content:center;'
                      class='details-check' @click.stop=''>
                      <!-- <img style=' width: 50px;height: 50px;object-fit: contain;'
                        src="../../assets/images/order_lock.png" alt=''>
                      <div class=''>登录即可查看运价</div>
                      <div>
                        <el-button type="primary" plain class="to-login-btn"
                          @click="busShowLoginPopover">去登录</el-button>
                      </div> -->
                      <template v-if="bluringType === 'login'">
                        <img style=' width: 50px;height: 50px;object-fit: contain;'
                          src="../../assets/images/order_lock.png" alt=''>
                        <div class='title'>登录即可查看运价</div>
                        <div>
                          <el-button type="primary" plain class="to-login-btn"
                            @click="busShowLoginPopover">去登录</el-button>
                        </div>
                      </template>
                      <template v-else>
                        <img style=' width: 50px;height: 50px;object-fit: contain;'
                        :src="ossPath + '/common/unapproval-ico.png'" alt=''>
                        <div class='title'>审核通过即可查看运价</div>
                      </template>
                    </div>
                  </template>
                  <div @mouseenter="getInfoFromPart(scope.row.zoneGroupId)" class="emailAddress">**</div>
                </el-tooltip>
                <!-- <div v-if="isBlurring" @mouseenter="watchMouseEnter" @mouseleave="watchMouseLeave">
                  **
                </div> -->

                <!-- <template v-else>
                  {{ returnContent(scope.row, item) }}
                </template> -->
              </template>
              <template v-else-if="item.prop == 'priceSectionName'">
                <el-tooltip transition="linear" effect="light" placement="right" :content="departInfo" raw-content>
                  <div @mouseenter="getInfoFromPart(scope.row.zoneGroupId)" class="emailAddress">{{
                    returnContent(scope.row, item) }}</div>
                </el-tooltip>

              </template>
              <template v-else-if="item.prop != 'priceSectionName'">
                {{ returnContent(scope.row, item) }}
              </template>

            </slot>
          </template>
        </el-table-column>
        <el-table-column :width="item.width ? item.width : undefined" v-if="item.flag">
          <template #header>
            <div style="display: flex; flex-direction: column;">
              <span>{{ item.calculateMethod }}</span>
              <span>{{ item.label }}</span>
            </div>

          </template>
          <template #default="scope">
            <el-tooltip v-if="isBlurring" transition="linear" show-after="100" effect="light" placement="top" popper-class="details-pop">
              <template #content>
                <div
                  style=' width: 140px;height: 130px;gap:16px; display:flex;flex-direction:column;align-items:center;justify-content:center;'
                  class='details-check' @click.stop=''>
                  <template v-if="bluringType === 'login'">
                    <img style=' width: 50px;height: 50px;object-fit: contain;'
                      src="../../assets/images/order_lock.png" alt=''>
                    <div class='title'>登录即可查看运价</div>
                    <div>
                      <el-button type="primary" plain class="to-login-btn"
                        @click="busShowLoginPopover">去登录</el-button>
                    </div>
                  </template>
                  <template v-else>
                    <img style=' width: 50px;height: 50px;object-fit: contain;'
                    :src="ossPath + '/common/unapproval-ico.png'" alt=''>
                    <div class='title'>审核通过即可查看运价</div>
                  </template>
                </div>
              </template>
              
              <span class="themeColor" >***</span>
            </el-tooltip>
            <template v-else>
              <span :class="{smallSize: setChargePrice.preferentialFlag}" class="themeColor" v-if="!setChargePrice.preferentialFlag">{{ returnContent(scope.row, item) }}</span>
              <template v-else>
                <div class="themeColor" v-if="!returnContent(scope.row, item).length">/</div>
                <template v-else>
                  <div :class="{smallSize: setChargePrice.preferentialFlag}" class="themeColor">{{returnContent(scope.row, item)[0]}}</div>
                  <div :class="{smallSize: setChargePrice.preferentialFlag}" style="text-decoration: line-through; color: #999999">{{returnContent(scope.row, item)[1]}}</div>
                </template>
              </template>
            </template>
          </template>

        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script setup>
import { userPriceBlurring } from "@/components/priceBlurring/priceBlurring";
import { getZoneGroup } from './simpleDataTable.js'
import { busShowLoginPopover } from "@/utils/bus";
import { ossPath } from "@/../config/variables";

const props = defineProps({
  tableData: {
    type: Array,
    default: () => [
      // {label:"产品名称",prop:"productName"},
      // {label:"价格",prop:"price"},
    ]
  },
  theadData: {
    type: Array,
    default: () => []
  },
  // 固定高度
  height: {
    type: [String, Number],
  },
  // 最大高度
  maxHeight: {
    type: [String, Number],
  },
  // Table 的尺寸 large / default /small(同element-plus)
  size: {
    type: String,
    default: 'default'
  },
  border: Boolean,
  headerCellStyle: {
    type: Object,
    default: () => {
      return { background: '#F1F3F8', color: '#333333' }
    }
  },
  quoteType: {
    type: String
  },
  setChargePrice: {
    type: Object,
    default: () => {
      return { preferentialFlag: false }
    }
  },
})
// 头程报价
if (props.quoteType === 'head') {

}
console.log(props, '1111111');
// 控制登录弹窗
let controlLogin = ref(false)


// 是否需要模糊价格
let { isBlurring,bluringType } = userPriceBlurring();
function returnContent(row, theadItem) {
  let value = row[theadItem.prop];
  if (theadItem.flag && props.setChargePrice?.preferentialFlag) {
    return value !== '/' && value !== '单询' ? [value || value === 0 ? '优惠价' + value : "-", value || value === 0 ? '原价' + (value + props.setChargePrice?.preferentialRange).toFixed(2) : "-"] : [value]
  }
  return value || value === 0 ? value : "-"
}

//  抛出监听方法
let emit = defineEmits(['mouseenterStuff', 'mouseleaveStuff'])

// 存储当前鼠标位置
let mousePointer = reactive({
  data: {
    pageX: '',
    pageY: ''
  }
})

// 弹窗值
// let dialogValueBtn = ref(" <div style=' width:134px;height:107px;gap:16px; display:flex;flex-direction:column;align-items:center;justify-content:center;' class='details-check'  @click.stop=''><img style=' width: 50px;height: 50px;object-fit: contain;' src='https://saastms.oss-cn-shenzhen.aliyuncs.com/2024020517/20240205171140fuyoken2024/02/05/register171140.png' alt=''><div class=''>登录即可查看运价</div><div><el-button>去登陆</el-button></div></div>")

/**
 * 监听鼠标当前位置
 * @param event 移动事件
 * */
function watchMouseStuff(event) {
  mousePointer.data.pageX = event.clientX
  mousePointer.data.pageY = event.clientY
}

/**
 * 监听鼠标移入位置
 * @param event 移入事件
 * */
function watchMouseEnter(event) {
  emit('mouseenterStuff', mousePointer.data)
}

/**
 * 监听鼠标移出位置
 * @param event 移出事件
 * */
function watchMouseLeave(event) {
  // console.log("mousePointer.data", mousePointer.data.pageX, mousePointer.data.pageY)
  // console.log("鼠标移出了")
  emit('mouseleaveStuff', mousePointer.data)
}

// 分区详情
let departInfo = ref()

/**
 * 根据分区id，查看分区值
 * @param id 分区id
 * */
let getInfoFromPart = async (id) => {
  // departInfo.value = ''
  let resData = []
  let strData = ''
  departInfo.value = "<div class='detailBox' style='width: 400px;max-width:400px;height: fit-content; display: flex;flex-direction: column;border: 1px solid #ebeef5 ;line-height:40px;color:#303133'><div style='width: 100%; height: 39px;border-bottom: 1px solid #ebeef5;padding-left:10px;'>分区信息</div><div style='width: 100%;height: fit-content;max-height:200px;overflow-y:auto;overflow-x:hidden;padding-left:10px;'>" + "数据加载中，请稍候..." + "</div></div>"
  await getZoneGroup(id)
    .then(res => {
      if (res.returnCode == 200 && res.data?.detailList?.length > 0) {
        res.data.detailList.map(item => {
          resData.push(item.value)
        })

      }
      else {
        resData = []
      }
    })
    .catch(res => {
      ElMessage.warning(res.message)
    })
  if (resData.length > 0) {
    strData = resData.join('、')
  }
  else {
    strData = ''
  }
  departInfo.value = "<div class='detailBox' style='width: 400px;max-width:400px;height: fit-content; display: flex;flex-direction: column;border: 1px solid #ebeef5 ;line-height:40px;color:#303133'><div style='width: 100%; height: 39px;border-bottom: 1px solid #ebeef5;padding-left:10px;'>分区信息</div><div style='width: 100%;height: fit-content;max-height:200px;overflow-y:auto;overflow-x:hidden;padding-left:10px;'>" + strData + "</div></div>"
}

</script>

<style scoped lang="scss">
.simple-data-table {
  :deep(.el-table__header-wrapper) {
    thead {
      &>tr {
        border-radius: 10px 10px 0 0;

        &>th {
          &:first-child {
            // border-top-left-radius: inherit;
          }

          &:last-child {
            // border-top-right-radius: inherit;
          }
        }
      }
    }
  }
}
.details-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 10px;
  // // position: absolute;
  // // top: -160px;
  // // left: 50%;
  // transform: translateX(-50%);
  // width: 154px;
  // height: 177px;
  width: 140px;
  min-height: 130px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 9999;
  cursor: default;

  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  .title {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #333333;
  }

  .btn {
    width: 105px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 4px;
    // border: 1px solid #2A63ED;
    border: 1px solid var(--el-color-primary);
    text-align: center;
    line-height: 30px;
    // color: #2A63ED;
    color: var(--el-color-primary);
    cursor: pointer;
  }
}

:deep(.el-popper) {
  margin: 0;
  padding: 0;
}

.tipStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 120px;
  height: 140px;

  .goLogin {
    width: 120px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--el-color-primary);
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 4px;
  }
}
.themeColor{
  color: var(--el-color-primary);
}
.smallSize {
  font-size: 12px;
}
.title {
  // max-width: 84px;
  white-space: break-spaces;
  text-align: center;
}
</style>
<style>
.el-popper.details-pop {
  padding: 0;
  background: transparent;
  border: none;
}
</style>
