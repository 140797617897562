<template>
  <div>
    <el-affix v-if="affix" :offset="affixTop" :z-index="200" @scroll="handleScroll">
      <div class="main-top-header" :class="[{'is-collapse':isCollapse}]" v-show="visible">
        <div class="main-top-header--container" :class="[styleClass,{'is-fixed':isFixed },{'is-absolute':isAbsolute }]">
          <div class="main-top-header--content page-size-container">
            <div class="logo-box" @click="goHome">
              <img :src="systemStore.webFooterInfo.websiteLogoUrl" alt="" class="logo" v-if="systemStore.webFooterInfo.websiteLogoUrl">
              <template v-else>
                {{ systemStore.webFooterInfo.omsWebsiteName||"-" }}
              </template>
            </div>
            <div class="flex-1"></div>
            <div class="flex-1"></div>
            <div style="height: 100%; display: flex; align-items: center; justify-content: flex-end; ">
              <main-top-header-menu class="main-menu-list" :menu-list="menuList" :current-menu-value="currentMenuValue"/>
              <div class="flex-3"></div>
              <layout-header-user class="font-18"/>
            </div>
          </div>
        </div>
      </div>
    </el-affix>
    <template v-else>
      <div class="main-top-header" :class="[{'is-collapse':isCollapse}]" v-show="visible">
        <div class="main-top-header--container" :class="[styleClass,{'is-fixed':isFixed },{'is-absolute':isAbsolute }]">
          <div class="main-top-header--content page-size-container">
            <div class="logo-box" @click="goHome">
              <img :src="systemStore.webFooterInfo.websiteLogoUrl" alt="" class="logo" v-if="systemStore.webFooterInfo.websiteLogoUrl">
              <template v-else>
                {{ systemStore.webFooterInfo.omsWebsiteName||"-" }}
              </template>
            </div>
            <div class="flex-1"></div>
            <div class="flex-1"></div>
            <div style="height: 100%; display: flex; align-items: center; justify-content: flex-end; ">
              <main-top-header-menu class="main-menu-list" :menu-list="menuList" :current-menu-value="currentMenuValue"/>
              <div class="flex-3"></div>
              <layout-header-user class="font-18"/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import LayoutHeaderUser from "@/layout/header/layoutHeaderUser.vue";
import MainTopHeaderMenu from "@/components/mainTopHeader/mainTopHeaderMenu.vue";
import {useSystemStore} from "@/stores";
import { useRouter } from "vue-router";

const router = useRouter();
let systemStore =  useSystemStore();
const props = defineProps({
  visible:{
    type:Boolean,
    default:true,
  },
  // 内层容器是否 absolute 定位
  isAbsolute:Boolean,
  // 内层容器是否 fixed 定位
  isFixed:Boolean,
  // 是否塌陷,为ture时外层容器高度=0
  isCollapse:Boolean,
  // 样式风格类型 default transparent
  styleType:{
    type:String,
    default:"default",
  },
  isWhiteStyle: {
    type:Boolean,
    default:true,
  },
  isTransparentStyle:Boolean,
  // 菜单列表
  menuList:Array,
  // 当前菜单激活项
  currentMenuValue:{
    type:String,
    // default:"home",
  },
  affix: {
    type:Boolean,
    default:true
  },
  affixTop: {
    type:Number,
    default:0
  }
})
const styleClass = computed(()=>{
  let defaultStyle = "";
  if(props.isTransparentStyle) {
    return "is-transparent-style"
  }else if(props.isWhiteStyle){
    return "is-white-style"
  }
  return defaultStyle
})
function goHome() {
  router.push({
    path: "/",
  });
}
</script>

<style scoped lang="scss">
.main-top-header{
  box-sizing: border-box;
  position: relative;
  z-index: 8;
  &:not(.is-collapse){
    height: var(--main-top-header-heigth);
  }
  &.is-collapse &--container{
    height: var(--main-top-header-heigth);
  }
  &--container{
    height: 100%;
    box-sizing: border-box;
    &.is-absolute,
    &.is-fixed{
      top: 0;
      left: 0;
      right: 0;
    }
    &.is-fixed{
      position: fixed;
    }
    &.is-absolute{
      position: absolute;
    }
  }
  &--content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
  }
}
.logo-box{
  // width: 150px;
  // height: 100%;
  height: 70px;
  box-sizing: border-box;
  //height: 2px;
  //background-color: #eee;
  font-size: 30px;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
  cursor: pointer;
  img{
    box-sizing: border-box;
    display: block;
    border: none;
    // width: auto;
    // max-width: 100%;
    height: 100%;
    // max-height: 100%;
  }
}
.main-menu-list{
  padding: 0;
  margin: 0;
  margin-right: 42px;
  list-style-type: none;
  display: flex;
  align-items: center;
  height: 100%;
  :deep(.menu-item){
    //padding: 0 22px;
    padding: 0;
    height: 100%;
    position: relative;
    z-index: 1;
    // font-size: 18px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-left: 45px;
    &:first-child{
      margin-left: 0;
    }
    &.is-active{
      color: var(--el-color-primary);
    }
    &:hover{
      color: var(--el-color-primary);
    }
    .menu-item--a{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      color: inherit;
    }
    &.is-active .menu-item--a{
      &:before{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: var(--el-color-primary);
      }
    }
  }
}
.is-transparent-style{
  background-color: transparent;
}
.is-white-style{
  background-color: white !important;
}
.font-18 {
  font-size: 18px
}
.el-affix {
  width: 100%!important;
  height: var(--main-top-header-heigth);
}
</style>
