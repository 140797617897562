<template>
  <div class="single-country" @click="emit('lookDetails')">
    <img class="charge-price-ico" v-if="preferentialFlag && !needInquiryFlag" :src="ossPath + '/common/charge-price-r.png'" />
    <!--    <div class="one-screen"></div>-->
    <div class="cover-box">
      <img :src="countryCover" alt="" class="" />
    </div>
    <div class="country-info">
      <div class="country-name text-over-hide">{{ countryName }}</div>
      <!--      <div class="price-row">-->
      <!--        <span class="currency">{{currency}}&nbsp;</span>-->
      <!--        <span class="price">{{price}}</span>-->
      <!--        <span class="unit">&nbsp;/{{chargeUnit}}</span>-->
      <!--      </div>-->
      <priceBlurringCountry :price="price" :currency="currency" :unit="chargeUnit" :inquiry="needInquiryFlag" class="price-row" />
      <div class="details-row">
        <el-link type="primary"
          >查看详情<el-icon> <TopRight /> </el-icon
        ></el-link>
      </div>
      <!-- 未登录时，弹窗提示去登录 -->
      <div class="details-check" @click.stop="" v-if="isBlurring">
        <!-- <img src="../../../../assets/images/order_lock.png" alt="" />
        <div class="title">登录即可查看运价</div>
        <el-button type="primary" plain @click="busShowLoginPopover()"
          >去登录</el-button
        > -->
        <template v-if="bluringType === 'login'">
          <img src="../../../../assets/images/order_lock.png" alt="" />
          <div class="title">登录即可查看运价</div>
          <el-button type="primary" plain @click="busShowLoginPopover()"
            >去登录</el-button
          >
        </template>
        <template v-else>
          <img :src="ossPath + '/common/unapproval-ico.png'" alt="" />
          <div class="title">审核通过即可查看运价</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import priceBlurringCountry from "@/components/priceBlurring/priceBlurringCountry.vue";
import { userPriceBlurring } from "@/components/priceBlurring/priceBlurring";
import { busShowLoginPopover } from "@/utils/bus";
import { ossPath } from "@/../config/variables";

const emit = defineEmits(["lookDetails"]);
const props = defineProps({
  index: Number,
  countryCover: String,
  countryName: String,
  currency: String,
  chargeUnit: String,
  needInquiryFlag: Boolean,
  preferentialFlag: Boolean,
  price: [String, Number],
});
// 是否需要模糊价格
let { isBlurring, bluringType } = userPriceBlurring();
const defaultCover = ossPath + "/pages/home/hot_country_default_icon.png";
</script>


<style scoped lang="scss">
.single-country {
  position: relative;
  box-sizing: border-box;
  //width: 250px;
  //height: 130px;
  border: 1px solid #eee;
  border-radius: 10px;
  position: relative;
  transition: ease 0.4s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // overflow: hidden;
  .charge-price-ico {
    position: absolute;
    width: 60px;
    height: 60px;
    top: -11px;
    right: -13px;
  }

  .details-check {
    display: none;
  }

  &:nth-child(9):hover {
    .details-check {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      position: absolute;
      top: -160px;
      left: 50%;
      transform: translateX(-50%);
      width: 154px;
      height: 177px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 9999;
      cursor: default;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      .title {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .btn {
        width: 105px;
        height: 30px;
        background: #ffffff;
        border-radius: 4px;
        // border: 1px solid #2A63ED;
        border: 1px solid var(--el-color-primary);
        text-align: center;
        line-height: 30px;
        // color: #2A63ED;
        color: var(--el-color-primary);
        cursor: pointer;
      }
    }
  }

  &:nth-child(10):hover {
    .details-check {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      position: absolute;
      top: -160px;
      left: 50%;
      transform: translateX(-50%);
      width: 154px;
      height: 177px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 9999;
      cursor: default;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      .title {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .btn {
        width: 105px;
        height: 30px;
        background: #ffffff;
        border-radius: 4px;
        // border: 1px solid #2A63ED;
        border: 1px solid var(--el-color-primary);
        text-align: center;
        line-height: 30px;
        // color: #2A63ED;
        color: var(--el-color-primary);
        cursor: pointer;
      }
    }
  }

  &:nth-child(11):hover {
    .details-check {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      position: absolute;
      top: -160px;
      left: 50%;
      transform: translateX(-50%);
      width: 154px;
      height: 177px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 9999;
      cursor: default;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      .title {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .btn {
        width: 105px;
        height: 30px;
        background: #ffffff;
        border-radius: 4px;
        // border: 1px solid #2A63ED;
        border: 1px solid var(--el-color-primary);
        text-align: center;
        line-height: 30px;
        // color: #2A63ED;
        color: var(--el-color-primary);
        cursor: pointer;
      }
    }
  }

  &:nth-child(12):hover {
    .details-check {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      position: absolute;
      top: -160px;
      left: 50%;
      transform: translateX(-50%);
      width: 154px;
      height: 177px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 9999;
      cursor: default;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      .title {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .btn {
        width: 105px;
        height: 30px;
        background: #ffffff;
        border-radius: 4px;
        // border: 1px solid #2A63ED;
        border: 1px solid var(--el-color-primary);
        text-align: center;
        line-height: 30px;
        // color: #2A63ED;
        color: var(--el-color-primary);
        cursor: pointer;
      }
    }
  }

  &:hover {
    .details-check {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      position: absolute;
      padding: 10px;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
      // width: 154px;
      // height: 177px;
      width: 140px;
      min-height: 130px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 9999;
      cursor: default;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }

      .title {
        // max-width: 84px;
        white-space: break-spaces;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .btn {
        width: 105px;
        height: 30px;
        background: #ffffff;
        border-radius: 4px;
        // border: 1px solid #2A63ED;
        border: 1px solid var(--el-color-primary);
        text-align: center;
        line-height: 30px;
        // color: #2A63ED;
        color: var(--el-color-primary);
        cursor: pointer;
      }
    }
    background-color: var(--el-color-primary);

    .country-name {
      color: #ffffff;
    }

    .price-row.price-blurring {
      --pb-color: #ffffff;
      --pb-price-color: #ffffff;
    }
  }
}

.one-screen {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 10px;
}

.cover-box {
  width: 100px;
  height: 66px;
  background: #ffffff;
  //border: 2px solid #FFFFFF;
  border-radius: 5px;
  margin-right: 12px;
  box-sizing: border-box;

  img {
    border-radius: inherit;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border: none;
  }
}

.country-info {
  flex: 1;
  overflow: hidden;
}

.country-name {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #333333;
  transition: color ease 0.4s;
}

.price-row {
  font-size: 14px;
  font-weight: 500;
  color: #636772;
  margin-top: 12px;
  transition: color ease 0.4s;
  //.currency{}
  //.price{
  //  color: #FF2A14;
  //  font-size: 18px;
  //}
  //.unit{}
}

.details-row {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  margin-top: 12px;
  display: none;
}
</style>
